export default {

    setData(state, data){
        state.data = data;

    },
    setSData(state, data){
        state.simpleData = data;

    },
    setPub(state, data){
        state.pub = data;
    }
    
}
<template>
    <div id="cryptoon-layout" class="theme-blue">
        <!-- sidebar -->
        <div class="sidebar py-2 py-md-2 me-0 border-end">
            <div class="d-flex flex-column h-100 mrg">
                <!-- Logo -->
                <a href="/" class="mb-0 brand-icon">
                    <img src="./../assets/home/assets/images/LUMIERE DU MONDE FINI.png" class="img-fluid img-responsive"
                        width="100" />
                </a>
                <!-- Menu: main ul -->
                <ul class="menu-list flex-grow-1 mt-4 px-1">
                    <li>
                        <a class="m-link active" @click="goTo('dashboard')">

                            <div>
                                <h6 class="mb-0">{{ $t("user.nav.sidebar.text1") }}</h6>
                                <small class="text-muted">{{
                                    $t("user.nav.sidebar.stext1")
                                }}</small>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a class="m-link active" @click="goTo('exchange1')">

                            <div>
                                <h6 class="mb-0">{{ $t("user.nav.sidebar.text2") }}</h6>
                                <small class="text-muted">{{
                                    $t("user.nav.sidebar.stext1")
                                }}</small>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a class="m-link active" @click="goTo('exchange2')">

                            <div>
                                <h6 class="mb-0">{{ $t("user.nav.sidebar.text3") }}</h6>
                                <small class="text-muted">{{
                                    $t("user.nav.sidebar.stext3")
                                }}</small>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a class="m-link active" @click="goTo('service')">

                            <div>
                                <h6 class="mb-0">{{ $t("user.nav.sidebar.text4") }}</h6>
                                <small class="text-muted">{{ $t("user.nav.sidebar.stext4") }}
                                </small>
                            </div>
                        </a>
                    </li>

                    <!-- <li class="collapsed">
            <a class="m-link" data-bs-toggle="collapse" data-bs-target="#widget" href="#">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 32 32">
                <path
                  d="M15.5,13.5c-1.65,0-3,1.35-3,3c0,1.65,1.35,3,3,3c1.65,0,3-1.35,3-3  C18.5,14.85,17.15,13.5,15.5,13.5z M15.5,13.5c-1.65,0-3,1.35-3,3c0,1.65,1.35,3,3,3c1.65,0,3-1.35,3-3  C18.5,14.85,17.15,13.5,15.5,13.5z M15.5,4.5c-6.62,0-12,5.38-12,12s5.38,12,12,12s12-5.38,12-12S22.12,4.5,15.5,4.5z M15.5,24.5  c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S19.91,24.5,15.5,24.5z M15.5,13.5c-1.65,0-3,1.35-3,3c0,1.65,1.35,3,3,3  c1.65,0,3-1.35,3-3C18.5,14.85,17.15,13.5,15.5,13.5z"
                  style="fill:var(--primary-color);" data-st="fill:var(--chart-color4);"></path>
                <path class="st2"
                  d="M15.5,8.5c-4.41,0-8,3.59-8,8s3.59,8,8,8s8-3.59,8-8S19.91,8.5,15.5,8.5z M15.5,19.5c-1.65,0-3-1.35-3-3  c0-1.65,1.35-3,3-3c1.65,0,3,1.35,3,3C18.5,18.15,17.15,19.5,15.5,19.5z"
                  style="fill:#FFFFFF;;"></path>
                <path class="st0"
                  d="M15.5,4C8.6,4,3,9.6,3,16.5C3,23.4,8.6,29,15.5,29C22.4,29,28,23.4,28,16.5C28,9.6,22.4,4,15.5,4z M15.5,28  C9.16,28,4,22.84,4,16.5S9.16,5,15.5,5S27,10.16,27,16.5S21.84,28,15.5,28z M15.5,8C10.81,8,7,11.81,7,16.5  c0,4.689,3.81,8.5,8.5,8.5c4.689,0,8.5-3.811,8.5-8.5C24,11.81,20.189,8,15.5,8z M15.5,24C11.36,24,8,20.641,8,16.5  C8,12.36,11.36,9,15.5,9c4.141,0,7.5,3.36,7.5,7.5C23,20.641,19.641,24,15.5,24z M15.5,13c-1.93,0-3.5,1.57-3.5,3.5  c0,1.93,1.57,3.5,3.5,3.5c1.93,0,3.5-1.57,3.5-3.5C19,14.57,17.43,13,15.5,13z M15.5,19c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5  s2.5,1.12,2.5,2.5S16.88,19,15.5,19z"
                  style="fill:#4D5152;;"></path>
              </svg>
              <div>
                <h6 class="mb-0">Widget</h6><small class="text-muted">Pages, Table, Chart</small>
              </div>

              <span class="arrow icofont-rounded-down ms-auto text-end fs-5" v-b-toggle.collapse-2></span>
            </a>
             #Menu: Sub menu ul 
            <b-collapse id="collapse-2" class="mt-2">
              <ul class="sub-menu" id="widget">
                <li><a class="ms-link" href="widget-example.html">Widget Example</a></li>
                <li><a class="ms-link" href="apexchart.html">Apex Chart</a></li>
                <li><a class="ms-link" href="priceplan.html"> Price Plan</a></li>
                <li><a class="ms-link" href="profile.html"> Profile Page</a></li>
                <li><a class="ms-link" href="table.html"> Tables</a></li>
                <li><a class="ms-link" href="todo-list.html"> Todo-List</a></li>
                <li><a class="ms-link" href="support-ticket.html"> Support-Ticket</a></li>
              </ul>
            </b-collapse>

          </li>-->
                </ul>
                <!--Menu: menu collepce btn -->
                <button type="button" class="btn btn-link sidebar-mini-btn text-muted" @click="SidebarMiniToggle">
                    <span><i class="icofont-bubble-right"></i></span>
                </button>
            </div>
        </div>

        <!-- main body area -->
        <div class="main px-lg-4 px-md-4">
            <!-- Body: Header -->
            <div class="header">
                <nav class="navbar py-4">
                    <div class="container-xxl">
                        <!-- header rightbar icon -->
                        <div class="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
                            <div class="d-flex"></div>

                            <div style="color: red;cursor: pointer;"
                                @click="al('https://formation.lumiercrypto.com/login?auth=' + auth)">
                                <img src="https://img.icons8.com/color/2x/book.png" width="40" />
                            </div>

                            <div class="dropdown">
                                <b-dropdown :text="lang" variant="outline-danger">
                                    <b-dropdown-item @click="setLang('en')"><img
                                            src="./../assets/home/assets/images/flag/GB.png" alt="" />
                                        English</b-dropdown-item>
                                    <b-dropdown-item @click="setLang('fr')"><img
                                            src="./../assets/home/assets/images/flag/FR.png" alt="" />
                                        French</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <b-dropdown variant="outline-danger">
                                <template #button-content>
                                    <img class="avatar rounded-circle img-thumbnail img-fluid"
                                        src="./../assets/home/assets/images2/profile_av.svg" alt="profile" width="50" />
                                </template>

                                <div class="card border-0">
                                    <div class="card-body pb-0">
                                        <div class="d-flex py-1">
                                            <img class="avatar rounded-circle"
                                                src="./../assets/home/assets/images2/profile_av.svg" alt="profile" />
                                            <div class="flex-fill ms-3">
                                                <p class="mb-0">
                                                    <span class="font-weight-bold">
                                                        {{ user.username }}</span>
                                                </p>
                                                <small class="">{{ user.email }}</small>
                                            </div>
                                        </div>

                                        <div>
                                            <hr class="dropdown-divider border-dark" />
                                        </div>
                                    </div>
                                    <div class="list-group m-2">
                                        <a class="list-group-item list-group-item-action border-0"
                                            @click="$router.push('profile')">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px"
                                                height="24px" viewBox="0 0 38 38" class="me-3">
                                                <path xmlns="http://www.w3.org/2000/svg"
                                                    d="M36.15,38H1.85l0.16-1.14c0.92-6.471,3.33-7.46,6.65-8.83c0.43-0.17,0.87-0.36,1.34-0.561  c0.19-0.08,0.38-0.17,0.58-0.26c1.32-0.61,2.14-1.05,2.64-1.45c0.18,0.48,0.47,1.13,0.93,1.78C15.03,28.78,16.53,30,19,30  c2.47,0,3.97-1.22,4.85-2.46c0.46-0.65,0.75-1.3,0.931-1.78c0.5,0.4,1.319,0.84,2.64,1.45c0.2,0.09,0.39,0.17,0.58,0.26  c0.47,0.2,0.91,0.391,1.34,0.561c3.32,1.37,5.73,2.359,6.65,8.83L36.15,38z M20,13v4h-2v-4H20z"
                                                    style="fill: var(--primary-color)" data-st="fill:var(--chart-color4);">
                                                </path>
                                                <path xmlns="http://www.w3.org/2000/svg" class="st0"
                                                    d="M21.67,17.34C21.22,18.27,20.29,19,19,19s-2.22-0.73-2.67-1.66l-1.79,0.891C15.31,19.78,16.88,21,19,21  s3.69-1.22,4.46-2.77L21.67,17.34z M15,10.85c-0.61,0-1.1,0.38-1.1,1.65s0.49,1.65,1.1,1.65s1.1-0.38,1.1-1.65S15.61,10.85,15,10.85  z M23,10.85c-0.61,0-1.1,0.38-1.1,1.65s0.489,1.65,1.1,1.65s1.1-0.38,1.1-1.65S23.61,10.85,23,10.85z M35.99,36.86  c-0.92-6.471-3.33-7.46-6.65-8.83c-0.43-0.17-0.87-0.36-1.34-0.561c-0.19-0.09-0.38-0.17-0.58-0.26c-1.32-0.61-2.14-1.05-2.64-1.45  c-0.521-0.42-0.7-0.8-0.761-1.29C26.55,22.74,28,19.8,28,17V4.56l-1.18,0.21C26.1,4.91,25.58,5,25.05,5  c-1.439,0-2.37-0.24-3.35-0.49C20.71,4.26,19.68,4,18.21,4c-1.54,0-2.94,0.69-3.83,1.9l1.61,1.18C16.5,6.39,17.31,6,18.21,6  c1.22,0,2.08,0.22,3,0.45C22.22,6.71,23.36,7,25.05,7c0.32,0,0.63-0.02,0.95-0.06V17c0,3.44-2.62,7-7,7s-7-3.56-7-7V6.29  C12.23,5.59,13.61,2,18.21,2c1.61,0,2.76,0.28,3.88,0.55C23.06,2.78,23.98,3,25.05,3C26.12,3,27.19,2.74,28,2.47V0.34  C27.34,0.61,26.17,1,25.05,1c-0.83,0-1.6-0.18-2.49-0.4C21.38,0.32,20.05,0,18.21,0c-5.24,0-7.64,3.86-8.18,5.89L10,17  c0,2.8,1.45,5.74,3.98,7.47c-0.06,0.49-0.24,0.87-0.76,1.29c-0.5,0.4-1.32,0.84-2.64,1.45c-0.2,0.09-0.39,0.18-0.58,0.26  c-0.47,0.2-0.91,0.391-1.34,0.561c-3.32,1.37-5.73,2.359-6.65,8.83L1.85,38h34.3L35.99,36.86z M4.18,36c0.81-4.3,2.28-4.9,5.24-6.12  c0.62-0.25,1.29-0.53,2-0.86c1.09-0.5,2.01-0.949,2.73-1.479c0.8-0.56,1.36-1.22,1.64-2.12C16.76,25.78,17.83,26,19,26  s2.24-0.22,3.21-0.58c0.28,0.9,0.84,1.561,1.64,2.12c0.721,0.53,1.641,0.979,2.73,1.479c0.71,0.33,1.38,0.61,2,0.86  c2.96,1.22,4.43,1.83,5.24,6.12H4.18z">
                                                </path>
                                            </svg>{{ $t("user.nav.navProfile.text1") }}
                                        </a>
                                        <a class="list-group-item list-group-item-action border-0"
                                            @click="$router.push('history')">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px"
                                                height="24px" viewBox="0 0 32 32" class="me-3">
                                                <path xmlns="http://www.w3.org/2000/svg"
                                                    d="M15.5,27.482C5.677,24.8,4.625,10.371,4.513,7.497C11.326,7.402,14.5,5.443,15.5,4.661  c0.999,0.782,4.174,2.742,10.986,2.836C26.375,10.371,25.323,24.8,15.5,27.482z"
                                                    style="fill: var(--primary-color)" data-st="fill:var(--chart-color4);">
                                                </path>
                                                <path xmlns="http://www.w3.org/2000/svg" class="st2"
                                                    d="M14.13,21.5c-0.801,0-1.553-0.311-2.116-0.873c-0.57-0.57-0.883-1.327-0.881-2.132  c0.001-0.8,0.314-1.55,0.879-2.11c0.555-0.563,1.297-0.876,2.093-0.885c0.131-0.001,0.256-0.054,0.348-0.146l4.63-4.63  c0.388-0.38,0.879-0.583,1.416-0.583s1.028,0.203,1.42,0.587c0.373,0.373,0.58,0.875,0.58,1.413c0,0.531-0.207,1.03-0.584,1.406  l-4.64,4.641c-0.094,0.095-0.146,0.222-0.146,0.354c0,0.782-0.311,1.522-0.873,2.087C15.693,21.189,14.938,21.5,14.13,21.5z">
                                                </path>
                                                <path xmlns="http://www.w3.org/2000/svg" class="st0"
                                                    d="M15.5,4c0,0-2.875,3-11.5,3c0,0,0,18,11.5,21C27,25,27,7,27,7C18.375,7,15.5,4,15.5,4z M15.5,26.984  C6.567,24.43,5.217,11.608,5.015,7.965C11.052,7.797,14.213,6.15,15.5,5.251c1.287,0.899,4.448,2.545,10.484,2.713  C25.782,11.608,24.434,24.43,15.5,26.984z M22.27,10.37c-0.479-0.47-1.1-0.73-1.77-0.73s-1.29,0.261-1.77,0.73L14.1,15  c-0.92,0.01-1.79,0.37-2.44,1.03c-1.37,1.358-1.37,3.579,0,4.95c0.66,0.66,1.54,1.02,2.47,1.02c0.94,0,1.82-0.359,2.479-1.02  c0.66-0.66,1.021-1.53,1.021-2.44l4.64-4.64C22.74,13.43,23,12.81,23,12.14C23,11.47,22.74,10.84,22.27,10.37z M21.561,13.2  l-4.949,4.95c0.1,0.75-0.13,1.539-0.71,2.119C15.41,20.76,14.77,21,14.13,21c-0.64,0-1.28-0.24-1.76-0.73  c-0.98-0.979-0.98-2.56,0-3.539c0.49-0.489,1.12-0.729,1.76-0.729c0.12,0,0.24,0.01,0.36,0.03l4.949-4.95  c0.291-0.3,0.681-0.44,1.061-0.44s0.77,0.141,1.061,0.44C22.15,11.66,22.15,12.61,21.561,13.2z M19.79,12.14l0.71,0.7l-5.02,5.021  c0.27,0.56,0.18,1.238-0.29,1.699c-0.58,0.59-1.53,0.59-2.12,0c-0.58-0.58-0.58-1.529,0-2.119c0.47-0.461,1.16-0.562,1.71-0.291  L19.79,12.14z M16,11H9v-1h7V11z M14,13H9v-1h5V13z">
                                                </path>
                                            </svg>{{ $t("user.nav.navProfile.text6") }}
                                        </a>

                                        <a class="list-group-item list-group-item-action border-0"
                                            @click="$router.push('refer')">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px"
                                                height="24px" viewBox="0 0 32 32" class="me-3">
                                                <path xmlns="http://www.w3.org/2000/svg"
                                                    d="M15,5v12c0,0,0,2-2,2H5c0,0-2,0-2-2L3.01,4.89C3.04,4.46,3.29,3,5,3h8C13,3,15,3,15,5z   M27,13h-8c-1.71,0-1.96,1.46-1.99,1.89L17,27c0,2,2,2,2,2h8c2,0,2-2,2-2V15C29,13,27,13,27,13z"
                                                    style="fill: var(--primary-color)" data-st="fill:var(--chart-color4);">
                                                </path>
                                                <path xmlns="http://www.w3.org/2000/svg" class="st0"
                                                    d="M13,3H5C3.29,3,3.04,4.46,3.01,4.89L3,17c0,2,2,2,2,2h8c2,0,2-2,2-2V5C15,3,13,3,13,3z M9,12.5  c-1.93,0-3.5-1.57-3.5-3.5S7.07,5.5,9,5.5s3.5,1.57,3.5,3.5S10.93,12.5,9,12.5z">
                                                </path>
                                                <path xmlns="http://www.w3.org/2000/svg" class="st0"
                                                    d="M14,16.12l0.99,0.99C14.96,17.54,14.71,19,13,19H5c0,0-2,0-2-2V5c0,0,0-2,2-2h8c0,0,2,0,2,2v7.88l-1-1V5  c0-0.8-0.55-0.99-1.01-1H5C4.19,4,4.01,4.55,4,5.01V17c0,0.81,0.55,0.99,1.01,1H13c0.81,0,0.99-0.55,1-1.01V16.12z M27,13h-8  c-1.71,0-1.96,1.46-1.99,1.89L18,15.88v-0.87c0.01-0.46,0.19-1.01,1-1.01h7.99c0.46,0.01,1.01,0.2,1.01,1v11.99  C27.99,27.45,27.81,28,27,28h-7.99C18.55,27.99,18,27.81,18,27v-6.88l-1-1V27c0,2,2,2,2,2h8c2,0,2-2,2-2V15C29,13,27,13,27,13z   M23,20h1v4h-4v-1h2.3L11.45,12.15C10.77,12.69,9.92,13,9,13c-2.21,0-4-1.79-4-4s1.79-4,4-4s4,1.79,4,4c0,0.92-0.31,1.77-0.85,2.45  L23,22.3V20z M12,9c0-1.65-1.35-3-3-3S6,7.35,6,9s1.35,3,3,3c0.64,0,1.25-0.21,1.74-0.56L9.15,9.85l0.7-0.7l1.59,1.59  C11.79,10.25,12,9.64,12,9z M22,26h4v-4h-1v3h-3V26z">
                                                </path>
                                            </svg>{{ $t("user.nav.navProfile.text4") }}</a>

                                        <a class="list-group-item list-group-item-action border-0" @click="logout()">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px"
                                                height="24px" viewBox="0 0 24 24" class="me-3">
                                                <rect xmlns="http://www.w3.org/2000/svg" class="st0" width="24" height="24"
                                                    style="fill: none ;" fill="none"></rect>
                                                <path xmlns="http://www.w3.org/2000/svg"
                                                    d="M20,4c0-1.104-0.896-2-2-2H6C4.896,2,4,2.896,4,4v16c0,1.104,0.896,2,2,2h12  c1.104,0,2-0.896,2-2V4z"
                                                    style="fill: var(--primary-color)" data-st="fill:var(--chart-color4);">
                                                </path>
                                                <path xmlns="http://www.w3.org/2000/svg" class="st0"
                                                    d="M15,6.81v2.56c0.62,0.7,1,1.62,1,2.63c0,2.21-1.79,4-4,4s-4-1.79-4-4c0-1.01,0.38-1.93,1-2.63V6.81  C7.21,7.84,6,9.78,6,12c0,3.31,2.69,6,6,6c3.31,0,6-2.69,6-6C18,9.78,16.79,7.84,15,6.81z M13,6.09C12.68,6.03,12.34,6,12,6  s-0.68,0.03-1,0.09V12h2V6.09z">
                                                </path>
                                            </svg>{{ $t("user.nav.navProfile.text5") }}
                                        </a>
                                    </div>
                                </div>
                            </b-dropdown>
                            <button class="navbar-toggler p-0 border-0 menu-toggle order-3" type="button"
                                data-bs-toggle="collapse" data-bs-target="#mainHeader" @click="SidebarToogle()">
                                <span class="fa fa-bars"></span>
                            </button>
                        </div>

                        <!-- menu toggler -->

                        <!-- main menu Search-->
                        <div class="
                    order-0
                    col-lg-4 col-md-4 col-sm-12 col-12
                    mb-3 mb-md-0
                    d-flex
                    align-items-center
                  ">
                            <div class="main-search border-start px-3 flex-fill"></div>
                        </div>
                    </div>
                </nav>
            </div>

            <!-- Body: Body -->

            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    methods: {
        logout() {
            Swal.fire({
                title: 'Are you sure?',
                text: "voulez voulez vous deconnecter/do you want to disconnect",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, deconnection!'
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("user/logout");
                    this.$router.go();
                }
            })

        },

        SidebarToogle() {
            this.$el.querySelector(".sidebar").classList.toggle("open");
            this.$el.querySelector(".open").classList.remove("sidebar-mini");
        },
        SidebarMiniToggle() {
            this.$el.querySelector(".sidebar").classList.toggle("sidebar-mini");
            this.$el.querySelector(".sidebar-mini").classList.remove("open");
        },
        setLang(value) {
            localStorage.setItem("lang", value);
            this.$router.go();
        },
        al(message) {
            window.open(message);
        }, goTo(to) {
            this.$router.push(to);
            this.$el.querySelector(".sidebar").classList.remove("open");
            this.$el.querySelector(".open").classList.remove("sidebar-mini");

        }
    },
    computed: {
        lang() {
            return localStorage.getItem("lang");
        },
        user() {
            return this.$store.state.user.user;
        },
        auth() {
            return this.$store.state.user.token;
        }
    },
};
</script>

<style>
@import url("./../assets/home/assets/css/dataTables.bootstrap5.min.css");
@import url("./../assets/home/assets/css/responsive.dataTables.min.css");

@import url("./../assets/home/assets/css/cryptoon.style.min.css");

.main {
    overflow-x: hidden;
}

.sidebar {
    background-color: #fff;
    margin: 0px !important;
}

.mrg {
    margin: 25px;
}

.btn-outline-danger {
    background-color: #f9fbfd !important;
    border: none !important;
}

.dropdown-menu {
    background-color: #fff !important;
    border: none !important;
}

.card {
    margin: 15px !important;
}

@media only screen and (max-width: 767px) {
    .h-right {
        margin-left: auto !important;
        padding-right: unset;
    }

}</style>

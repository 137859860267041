import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../plugins/i18n';
import meta from 'vue-meta'

//import RecoverPassView from '../views/RecoverPassView.vue'
import User from '../views/User.vue';
import store from '../store/index'

Vue.use(VueRouter)
Vue.use(meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})
const routes = [

  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue'),
    meta: {
      requireAuth: false,
      title: 'LUMIER DU MONDE CRYPTO',
      description: 'FAITES VOS EXCHANGES DE MONNAIES NUMERIQUES (CRYPTOMONNAIE, PERFECT-MONEY, ET AUTRE) AVEC LES MOYENS MOBILES , CARTES BANCAIRES , FAITES AUSSI CERTAINES ACHATS DIVERS AVEC VOS MOYENS NUMERIQUES DEPUIS LES METHODES DE LMC ',
      noindex: false
    }

  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/LoginView.vue'),
    meta: {
      requireAuth: false,
      title: 'CONNECTEZ VOUS',
      description: 'PAGE DE CONNEXION',
      noindex: false
    },
    beforeEnter: (to, from, next) => {
      let auth = store.getters['user/isAuth'];
      if (auth) {
        next('/exchange2')
      }
      next()
    }


  }, {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "Register" */ '../views/RegisterView.vue'),
    meta: {
      requireAuth: false,
      title: 'INSCRIVEZ-VOUS',
      description: 'PAGE INSCRIPTION',
      noindex: false
    }

  }, {
    path: '/about-us',
    name: 'about',
    component: () => import(/* webpackChunkName: "About us" */ '../views/About.vue'),
    meta: {
      requireAuth: false,
      title: 'A propos de nous',
      description: 'Voici un peu qui est lumier crypto',
      noindex: false
    }

  },
  {
    path: '/password_recover',
    name: 'passRecover',
    component: () => import(/* webpackChunkName: "passwordz" */ '../views/RecoverPassView.vue'),
    meta: {
      requireAuth: false,
      title: 'RECUPERER VOTRE MOT DE PASSE',
      description: 'PAGE DE RECUPERATION DE MOT DE PASSE',
      noindex: false
    }

  }, {
    path: '/user',
    redirect: 'dashboard',
    component: User,
    meta: { requireAuth: true },
    // eslint-disable-next-line no-sparse-arrays
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/Dashboard.vue'),
        meta: {
          requireAuth: true,
          title: 'ACCUEIL',
          description: 'PAGE  DE PUB ',
          noindex: true
        }
      }, {
        path: '/exchange1',
        name: 'exchange1',
        component: () => import(/* webpackChunkName: "simple" */ '../views/user/ExSimple.vue'),
        meta: {
          requireAuth: true,
          title: 'EXCHANGE SIMPLE',
          description: 'EXCHANGE SIMPLE',
          noindex: true
        }
      }, {
        path: '/exchange2',
        name: 'exchange2',
        component: () => import(/* webpackChunkName: "Pro" */ '../views/user/ExPro.vue'),
        meta: {
          requireAuth: true, title: 'EXCHANGE PRO',
          description: 'PAGE EXCHANGE VICE-VERCA',
          noindex: true
        }
      }, , {
        path: '/service',
        name: 'service',
        component: () => import(/* webpackChunkName: "service" */ '../views/user/Service.vue'),
        meta: {
          requireAuth: true, title: 'SERVICE LMC',
          description: 'PAGE  DE SERVICE',
          noindex: true
        }
      }, {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/user/Profil.vue'),
        meta: {
          requireAuth: true,
          title: 'PROFIL USER',
          description: 'PAGE DE PROFIL',
          noindex: true
        }
      }, {
        path: '/refer',
        name: 'refer',
        component: () => import(/* webpackChunkName: "refer" */ '../views/user/Refereal.vue'),
        meta: {
          requireAuth: true, title: 'GESTION DU REFERENCEMENT',
          description: 'PARAINAGE',
          noindex: true
        }
      }, {

        path: '/history',
        name: 'history',
        component: () => import(/* webpackChunkName: "security" */ '../views/user/History.vue'),
        meta: {
          requireAuth: true,
          title: 'HISTORIQUE DES TRANSACTION',
          description: 'PAGE  DE TRANSACTION',
          noindex: true
        }

      }

    ]
  }, {
    path: '*', component: () => import(/* webpackChunkName: "not Found" */ '../views/NotFound.vue')
  }





]


const router = new VueRouter({
  routes,
  mode: 'history'
})





router.beforeEach((to, from, next) => {

  let language = localStorage.getItem('lang');
  let auth = store.getters['user/isAuth'];
  if (!language) {
    language = 'fr'
    localStorage.setItem('lang', language);
  }

  if (to.meta.requireAuth && !auth) {
    next({ path: '/login' })
  }


  i18n.locale = language
  next()

});
export default router

import axios from "axios";


const feda = axios.create({
    baseURL:'https://api.fedapay.com/v1/transactions' /*'https://sandbox-api.fedapay.com/v1/transactions'*/,
    headers: {
        'Authorization': 'Bearer sk_live__xnAeOdF0ShAjxccb9tz1XSx',
        'Content-Type': 'application/json'
    }
})

export default feda
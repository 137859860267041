export default {
    


    setToken(state, token) {
        state.token = token;
         localStorage.setItem('_token', token);

    },
    setUser(state, user){
        state.user = user
    },
    setRefer(state, refer){
        state.userRefer = refer
    }
}
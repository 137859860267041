export default {


    setData(state, data) {

        state.data = data;
  

    },

    setSimple(state, data){
        
        state.simple = data;
    }
    
}
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


export default new Vuetify({
    icons: {
        iconfont : 'md'  || 'fa'
        
    },
       theme : {
        themes: {
            dark :{
                background : '#fff',
               },
           light: {
                background : '#f9fbfd',
                yellow: "#feaa00"
            }
        }
       }
   
   });
   

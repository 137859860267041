import axios from "axios";


export const lgcash = axios.create({
    baseURL: 'https://app.ligdicash.com/pay/v01/redirect/checkout-invoice/create',
    headers: {

        'Apikey': 'REV9DJR33TZ6J4I4O',
        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF9hcHAiOjY1NjAsImlkX2Fib25uZSI6MjQ2MzI0LCJkYXRlY3JlYXRpb25fYXBwIjoiMjAyNC0wNC0wOCAwODozMjoyNSJ9.n015LG9gTRuSUdzFlrEm5f8mybE5MmqeNinO5SSOaIM',
        'Accept': 'application/json',
    }
})


export const lgcashgn = axios.create({
    baseURL: 'https://app.ligdicash.com/pay/v01/redirect/checkout-invoice/create',
    headers: {

        'Apikey': 'MAGPMLT3QFJLIPUDN',
        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF9hcHAiOjE1OTI3LCJpZF9hYm9ubmUiOjI0NjMyNCwiZGF0ZWNyZWF0aW9uX2FwcCI6IjIwMjQtMDQtMDggMDg6MzI6MjQifQ.ar1DYi11lJEb6cUD4g9PzNQQDO_dPOwab_-qLRAOmkA',
        'Accept': 'application/json',
    }
})


export const lgcashcg = axios.create({
    baseURL: 'https://app.ligdicash.com/pay/v01/redirect/checkout-invoice/create',
    headers: {

        'Apikey': 'MAGPMLT3QFJLIPUDN',
        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF9hcHAiOjE1OTY3LCJpZF9hYm9ubmUiOjI0NjMyNCwiZGF0ZWNyZWF0aW9uX2FwcCI6IjIwMjQtMDQtMDggMDg6MzI6MjQifQ.Ks6rKip2eyJERP3v0NZAlxjG3c5uwuRZdWPRBjYpVrI',
        'Accept': 'application/json',
    }
})



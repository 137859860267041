export default {


      
    isAuth() {
        if (localStorage.getItem('_token') == 'null' || localStorage.getItem('_token') == null ) {
            return false
        } else {
            return true
        }
         
    }
    
}
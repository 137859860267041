import LmAPi from "@/plugins/LmApi"

export default {


    getCurrency(context) {

        LmAPi({
            method: 'GET',
            url: '/currencies'
        }).then((r)=>{
            var data = r.data;
            const dataf = data.filter((d) => d.topUp == true);
          context.commit('setData', r.data);
          context.commit('setSData', dataf);

        }).catch((e) => {
            console.log(e)

        })

    },
    getPub(context) {

        LmAPi({
            method: 'GET',
            url: '/pubs'
        }).then((r)=>{
            var data = r.data;
            const dataf = data.filter((d) => d.active == true);
          context.commit('setPub', dataf);

        }).catch((e) => {
            console.log(e)

        })

    }




}
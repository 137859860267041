
import store from "..";
import LmAPi from "../../plugins/LmApi";


export default {

    register(context, payload) {
        return new Promise((resolve, reject) => {
            context,
                LmAPi({
                    method: 'POST',
                    url: '/register',
                    data: payload,
                    headers: { 'accept': 'application/json' },
                    responseType: 'json'

                }).then(
                    (response) => {

                        resolve(response)

                    }).catch((error) => {

                        reject(error)
                    })

        })
    },
    login(context, payload) {

        return new Promise((resolve, reject) => {

            LmAPi({
                method: 'POST',
                url: '/login',
                data: payload,
                headers: { 'accept': 'application/json' },
                responseType: 'json'

            }).then((response) => {
                let datas = response.data;


                context.dispatch('attempt', response.data.token);




                resolve(response)
            }).catch((error) => {

                reject(error)
            })

        })
    },
    logout(context) {

        localStorage.removeItem('_token')
        context.commit('setToken', null)
        context.commit('setDatas', null)
        context.commit('authError')



    }, async attempt(context, token) {

        if (token) {
            context.commit('setToken', token)
        }

        if (!context.state.token) {
            return
        }
        try {
            let response = await
                LmAPi({
                    method: 'GET',
                    url: '/profile',
                    headers: {
                        'accept': 'application/json',

                    }
                })

            context.commit('setUser', JSON.parse(response.data.user));
            store.dispatch('currency/getCurrency', { root: true });
            store.dispatch('currency/getPub', { root: true });
            context.dispatch('getRefer')

            /*
               store.dispatch('method/MethodRecord', { root: true })
             
               context.dispatch('transaction/getTransaction', "/transactions?user=" + JSON.parse(response.data.user).id, { root : true})
             store.dispatch('service/getService', "/services?user=" + JSON.parse(response.data.user).id,{ root: true })
             
             store.dispatch('transaction/checkApi', { root: true })
             */
        } catch (error) {

            console.log(error)
            context.commit('setToken', null)
            context.commit('setUser', null)


        }
    },
    getRefer(context, payload) {
        LmAPi({
            method: 'GET',
            url: '/users?refer=' + context.state.user.referLink,
            headers: {
                'accept': 'application/json',

            }

        }).then((r) => {
            context.commit('setRefer', r.data);
            payload;

        })
    },
    addUserinfos(context, payload) {

        LmAPi({
            method: 'PUT',
            url: '/users/' + context.state.user.id,
            data: payload,
            headers: {
                'accept': 'application/json',

            }

        }).then((r) => {
            //  console.log(r)

        }).catch((e) => { console.log(e) })
    }


}
import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import status from './status'
import currency from './currency'
import transaction from './transaction'


Vue.use(Vuex)

export default new Vuex.Store({
  
  modules: {
    user,
    status,
    currency,
    transaction
  }
})

import axios from "axios";


const coinPay = axios.create({
    baseURL: 'https://lmcserve.com/pay',
    headers: { 'Accept': 'application/json' }

   
})

export default coinPay; 
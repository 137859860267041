import store from './index'
import LmApi from '../plugins/LmApi'

store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'user/setToken':
            if (mutation.payload) {
                LmApi.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;
              /*  coinPay.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;*/
            } else {
                LmApi.defaults.headers.common['Authorization'] = null
               
                localStorage.setItem('_token', null);

    
            }
            
            break;
    
    }
})
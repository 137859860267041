<template>
  <v-app :style="{background: $vuetify.theme.themes.light.background}">
  

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>


<style>

.v-application ul{
  padding-left: 5px !important;
}
.btn-secondary{

background-color: #82368c !important;
}

.btn-primary{
background-color: #36A6E1 !important;
}

.text-primary{
  color: #36A6E1 !important;
}
.modal-backdrop{
 
  opacity: 0.5;
}
.sidebar{
  height: 100vh !important;
}
.sidebar .sidebar-mini-btn {
 
    margin-bottom: 50px;
}
</style>
<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

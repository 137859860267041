import mutations from './mutation';
import actions from './action';
import getters from './getter';
import state from './state'
export default {
    namespaced: true,
    state,
  getters,
  mutations,
  actions
}